import { createApi } from '@reduxjs/toolkit/query/react'

import { API_NAMES, getDynamicBaseUrl } from './utils'

export enum Tags {
  EligibilityRecords = 'EligibilityRecords',
}

// TODO: Update once Scribe generates a TS client.

export interface CollectionEligibilityTag {
  data: EligibilityTag[]
}

export interface GetUserEligibilityTagsRequestParams {
  memberId: number
}

export interface EligibilityTag {
  id: number
  display_name: string
  short_name: string
  color: string
  admin_description: string
  provider_description_en: string
  provider_description_fr: string
}

export const scribeApi = createApi({
  tagTypes: Object.values(Tags),
  reducerPath: 'scribeApi',
  baseQuery: getDynamicBaseUrl(API_NAMES.SCRIBE),
  endpoints: (builder) => ({
    getUserEligibilityTags: builder.query({
      query: ({ memberId }: GetUserEligibilityTagsRequestParams) => ({
        url: `/v2/users/${memberId}/eligibility_tags`,
      }),
      transformResponse: (response: CollectionEligibilityTag) => response.data,
    }),
  }),
})

export const { useGetUserEligibilityTagsQuery } = scribeApi
